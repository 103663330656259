﻿/********************************************************************/
/*** Стандартное диалоговое окно для сообщений от AJAX приложений ***/
/********************************************************************/
#dialogWinBlock { font-size: 12px; }
#dialogWinBlock .msg_show { margin: 15px auto; }
.dialog_content_center { text-align: center; }

/********************************************/
/*** Стиль панели вывода silent-сообщений ***/
/********************************************/
.toast-container { width: 420px; z-index: 9999; }
* html .toast-container { position: absolute; }
.toast-item 
{
	height: auto;
	background: #333;
    opacity: 0.8;
	-moz-border-radius: 10px;
	-webkit-border-radius: 10px;
	color: #eee;
	padding-top: 20px;
	padding-bottom: 20px;
	padding-left: 6px;
	padding-right: 6px;
	font-family: lucida Grande;
	font-size: 14px;
	border: 2px solid #999;
	display: block;
	position: relative;
	margin: 0 0 12px 0;
}
.toast-item p { text-align: left; margin: 0 0 0 50px; padding: 0; color: white; }
.toast-item-close 
{
    background:url(/static/imgs_common/toastMessagePlugin/close.png);
    width:22px;
    height:22px;
    position: absolute;
    top: -7px;
    right: -7px;
	cursor: pointer;
}
.toast-item-image { width:32px; height: 32px; position: absolute; left: 10px; top: 12px; }
.toast-item-image-notice { background:url(/static/imgs_common/toastMessagePlugin/notice.png); }
.toast-item-image-success { background:url(/static/imgs_common/toastMessagePlugin/success.png); }
.toast-item-image-warning { background:url(/static/imgs_common/toastMessagePlugin/warning.png); }
.toast-item-image-error { background:url(/static/imgs_common/toastMessagePlugin/error.png); }

/* toast types */
.toast-type-notice { color: white; }
.toast-type-success { color: white;}
.toast-type-warning { color: white; border-color: #FCBD57; }
.toast-type-error { color: white; border-color: #B32B2B; }

/* positions */
.toast-position-top-left { position: fixed; left: 20px; top: 20px; }
.toast-position-top-center { position: fixed; top: 20px; left: 50%; margin-left: -140px; }
.toast-position-top-right { position: fixed; top: 20px; right: 20px; }
.toast-position-middle-left { position: fixed; left: 20px; top: 50%; margin-top: -40px; }
.toast-position-middle-center { position: fixed; left: 50%; margin-left: -140px; margin-top: -40px; top: 50%; }
.toast-position-middle-right { position: fixed; right: 20px; margin-left: -140px; margin-top: -40px; top: 50%; }

/************************************/
/*** Конец блока silent-сообщений ***/
/************************************/

/********************************************************/
/* Стандартные стили сообщений для функции ONG_MESSAGES */
/********************************************************/
/* Обвязочные блоки сообщений */
.defMessageBlockWrap { position: relative; display: block; margin: 1em auto; padding: 0; -webkit-box-sizing: border-box; -moz-box-sizing: border-box; box-sizing: border-box; }

/* Стили разных блоков сообщений */
.defMessageInformBlock { position: relative; display: table; width: 100%; margin: 0; padding: 0; vertical-align: middle; -webkit-box-sizing: border-box; -moz-box-sizing: border-box; box-sizing: border-box; }
.defMessageRequestBlock { position: relative; display: table; width: 100%; margin: 0; padding: 0; vertical-align: middle; -webkit-box-sizing: border-box; -moz-box-sizing: border-box; box-sizing: border-box; }
.defMessageBlankBlock { position: relative; display: table; width: 100%; margin: 0; padding: 0; vertical-align: middle; -webkit-box-sizing: border-box; -moz-box-sizing: border-box; box-sizing: border-box; }

/* Содержимое блока сообщения - иконка */
.defMessageBlockWrap .defMessageBlockIcon { display: table-cell; padding: 15px; margin: 0; width: 48px; vertical-align: middle; }

/* Содержимое блокасообщения - текст сообщения */
.defMessageBlockWrap .defMessageBlockContent { display: table-cell; padding: 15px 0; margin: 0; width: auto; vertical-align: middle; }
.defMessageBlockWrap .defMessageBlockContent h3 { font-size: 20px; font-weight: bold; padding-bottom: 5px;  }
.defMessageBlockWrap .defMessageBlockContent .message_text { text-align: left; }
.defMessageBlankBlock .defMessageBlockContent .message_text { margin-bottom: 0; }

/* Содержимое блокасообщения - управляющие кнопки */
.defMessageBlockWrap .defMessageBlockButton { display: table-cell; padding: 15px; margin: 0 0 0 25px; width: 150px; text-align: right; vertical-align: middle; }

/* Стили отображаемых сообщений "Ошибка/Успех/Информация/Предупреждение" */
.defMessageBlockWrap .error_style { background: #FBE3E4; color: #8A1F11; border: 1px solid #FBC2C4; }
.defMessageBlockWrap .inform_style { background: #E6EFC2; color: #264409; border: 1px solid #C6D880; }
.defMessageBlockWrap .success_style { background: #E6EFC2; color: #264409; border: 1px solid #C6D880; }
.defMessageBlockWrap .warning_style { background: #FFF6BF; color: #514721; border: 1px solid #FFD324; }

.defMessageBlockWrap .error_style .defaultButtonUIGrey { background-color: #cf232c; border: 1px solid #cf232c; margin: 0; color: #fff; }
.defMessageBlockWrap .error_style .defaultButtonUIGrey:hover { background-color: #DF343D; border: 1px solid #DF343D; color: #fff; }

.defMessageBlockWrap .success_style .defaultButtonUIGrey { background-color: #A3C02F; border: 1px solid #A3C02F; margin: 0; color: #fff; }
.defMessageBlockWrap .success_style .defaultButtonUIGrey:hover { background-color: #A3C02F; border: 1px solid #A3C02F; color: #fff; }

.defMessageBlockWrap .inform_style .defaultButtonUIGrey { background-color: #93AE27; border: 1px solid #93AE27; margin: 0; color: #fff; }
.defMessageBlockWrap .inform_style .defaultButtonUIGrey:hover { background-color: #A3C02F; border: 1px solid #A3C02F; color: #fff; }

.defMessageBlockWrap .warning_style .defaultButtonUIGrey { background-color: #D7B800; border: 1px solid #D7B800; margin: 0; color: #fff; }
.defMessageBlockWrap .warning_style .defaultButtonUIGrey:hover { background-color: #E8CA16; border: 1px solid #E8CA16; color: #fff; }
