﻿/**********************************/
/***** СТАНДАРТНЫЕ СТИЛИ ФОРМ *****/
/**********************************/

/*******************************************************************/
/* СТАНДАРТНЫЙ СТИЛЬ ПАНЕЛЕЙ ФОРМ ДОБАВЛЕНИЯ/РЕДАКТИРОВАНИЯ ДАННЫХ */
.default_work_data_panels { margin: 10px 0 0 0; border: none; width: 100%; }
.default_work_data_panels th { border-left: #ccc solid 1px; border-top: #ccc solid 1px; border-bottom: #ccc solid 1px; background: #F4F4EB;  }
.default_work_data_panels th:first-child { border-left: none; }
.default_work_data_panels th:hover { background-color: #FFE3B9; border-bottom: #ccc solid 1px; }
.default_work_data_panels th a { display: block; text-decoration: none; font-size: 16px; text-align: center; font-weight: bold; padding: 15px 0; color: #000; }
.default_work_data_panels th a img { margin: 4px; width: 48px; height: 48px; }
.default_work_data_panels th.current_panel { border-bottom: #fff solid 1px;; background: #FFF; }
.default_work_data_panels th.current_panel a { color: #F90; }

.defaultWorkPanelContentTableBlock { position: relative; display: block; margin: 0 0 10px; border: none; line-height: 1.5; }
.defaultWorkPanelContentFormBlock { position: relative; display: block; margin: 30px 20px 10px 20px; border: none; line-height: 1.5; }
.defaultWorkPanelButtonsBlock { position: relative; display: block; margin: 40px 0 10px 0; padding: 0 20px; border: none; width: 958px; line-height: 1.5; }

/*****************************************************************************/
/* СТАНДАРТНЫЙ СТИЛЬ ПАНЕЛЕЙ МОДАЛЬНЫХ ФОРМ ДОБАВЛЕНИЯ/РАДАКТИРОВАНИЯ ДАННЫХ */
.default_work_data_panels_modal { margin: 10px 0 0 0; border: none; width: 100%; }
.default_work_data_panels_modal th { border-left: #ccc solid 1px; border-top: #ccc solid 1px; border-bottom: #ccc solid 1px; background: #F4F4EB;  }
.default_work_data_panels_modal th:last-child { border-right: #ccc solid 1px; }
.default_work_data_panels_modal th:hover { background-color: #FFE3B9; border-bottom: #ccc solid 1px; }
.default_work_data_panels_modal th a { display: block; text-decoration: none; font-size: 16px; text-align: center; font-weight: bold; padding: 15px 0; color: #000; }
.default_work_data_panels_modal th a img { margin: 4px; width: 48px; height: 48px; }
.default_work_data_panels_modal th.current_panel { border-bottom: #fff solid 1px;; background: #FFF; }
.default_work_data_panels_modal th.current_panel a { color: #F90; }

.defaultWorkPanelContentTableBlockModal { position: relative; display: block; margin: 0 0 10px; border: none; line-height: 1.5; }
.defaultWorkPanelContentFormBlockModal { 
	position: relative; 
	display: block;
	margin: 0; 
	padding: 30px 20px 30px 20px; 
	border-left: #ccc solid 1px; 
	border-right: #ccc solid 1px; 
	border-bottom: #ccc solid 1px; 
	line-height: 1.5; 
}
.defaultWorkPanelButtonsBlockModal { position: relative; display: block; margin: 40px 0 10px 0; padding: 0 20px; border: none; width: 958px; line-height: 1.5; }

/*********************************************************************/
/* Стандартный стиль для AJAX форм редактирования в диалоговых окнах */
.ajaxFormStyleStandart { position: relative; margin: 0; padding: 10px 0; text-align: left; }
.ajaxFormStyleStandart .formTitle { position: relative; margin: 0; padding: 5px 20px; text-align: left; }
.ajaxFormStyleStandart .formWarning { display: inline-block; color: #F30; font-weight: bold; margin: 5px 0;}
.ajaxFormStyleStandart p.f_small_descr { font: Arial, Helvetica, sans-serif; font-size: 12px; margin: 10px 0; }

.ajaxFormStyleStandart label { font-family: Arial, Helvetica, sans-serif; font-size: 12px; }
.ajaxFormStyleStandart label.right_float { display: block; width: 200px; float: left; padding: 0 10px 0 0; }
.ajaxFormStyleStandart label.f_rlabel { font-weight: bold; margin-bottom: 15px; vertical-align: middle; }
.ajaxFormStyleStandart label.field { color: #999; position: absolute; padding: 4px 0 0 5px; height: 12px; cursor: text; font-size: 12px; }

.ajaxFormStyleStandart button.f_text_button { padding: 3px; vertical-align: middle; cursor: pointer; }
.ajaxFormStyleStandart span.f_icon { display: inline-block; width: 16px; height: 16px; background-repeat: no-repeat; }
.ajaxFormStyleStandart span.f_icon_sms { background-image: url('/static/imgs_common/icons/sms_small.png'); }
.ajaxFormStyleStandart span.f_icon_checked { background-image: url('/static/imgs_common/icons/check_small.png'); }
.ajaxFormStyleStandart span.f_icon_error { background-image: url('/static/imgs_common/icons/error_small.png'); }
.ajaxFormStyleStandart span.f_icon_warning { background-image: url('/static/imgs_common/icons/warning_small.png'); }
.ajaxFormStyleStandart span.f_icon_calendar { background-image: url('/static/imgs_common/icons/calendar.png'); }

.ajaxFormStyleStandart textarea,
.ajaxFormStyleStandart input,
.ajaxFormStyleStandart select { -webkit-box-sizing: border-box; -moz-box-sizing: border-box; box-sizing: border-box; max-width: 100% !important; }

.ajaxFormStyleStandart textarea.f_extra_small_textarea { width: 100%; height: 50px; resize: vertical; border: 1px solid #ccc; padding: 5px; margin: 0; margin-bottom: 15px; }
.ajaxFormStyleStandart textarea.f_small_textarea { width: 100%; height: 100px; resize: vertical; border: 1px solid #ccc; padding: 5px; margin: 0; margin-bottom: 15px; }
.ajaxFormStyleStandart textarea.f_textarea { width: 100%; resize: vertical; border: 1px solid #ccc; padding: 5px; margin: 0; margin-bottom: 15px; }
.ajaxFormStyleStandart textarea.f_big_textarea { width: 100%; height: 250px; resize: vertical; border: 1px solid #ccc; padding: 5px; margin: 0; margin-bottom: 15px; }
.ajaxFormStyleStandart input.f_text { width: 350px; border: 1px solid #ccc; padding: 5px; margin: 0; }
.ajaxFormStyleStandart input.f_text_small { width: 200px; border: 1px solid #ccc; padding: 5px; margin: 0; }
.ajaxFormStyleStandart input.f_text_wide {	width: 450px; border: 1px solid #ccc; padding: 5px; margin: 0; }
.ajaxFormStyleStandart input.f_text_extra_wide { width: 600px; border: 1px solid #ccc; padding: 5px; margin: 0; }
.ajaxFormStyleStandart input.f_date { width: 100px; border: 1px solid #ccc; padding: 5px; margin: 0; }
.ajaxFormStyleStandart input.f_numeric { width: 150px; border: 1px solid #ccc; padding: 5px; margin: 0; }
.ajaxFormStyleStandart input.f_kcaptcha { width: 117px; border: 1px solid #ccc; padding: 5px; margin: 0; }
.ajaxFormStyleStandart input.f_checkbox { margin: auto 20px; float: left; border: 1px solid #ccc; padding: 5px; margin: 0; }
.ajaxFormStyleStandart select { min-width: 350px; max-width: 400px; border: 1px solid #ccc; padding: 5px; margin: 0; }

.ajaxFormStyleStandart .f_switch_checkbox { vertical-align: top; margin: 0 3px 0 0; width: 17px; height: 17px; }  
.ajaxFormStyleStandart .f_switch_checkbox + label { cursor: pointer; }  
.ajaxFormStyleStandart .f_switch_checkbox:not(checked) { position: absolute; opacity: 0; }  
.ajaxFormStyleStandart .f_switch_checkbox:not(checked) + label { position: relative; display: inline-block; padding: 0 0 0 40px; }  
.ajaxFormStyleStandart .f_switch_checkbox:not(checked) + label:before { content: ''; position: absolute; top: -2px; left: 0; width: 30px; height: 16px; background: #CDD1DA; box-shadow: inset 0 2px 3px rgba(0,0,0,.2); border: 1px solid #999; }  
.ajaxFormStyleStandart .f_switch_checkbox:not(checked) + label:after { content: ''; position: absolute; top: 1px; left: 3px; width: 12px; height: 12px; background: #FFF; box-shadow: 0 2px 5px rgba(0,0,0,.3); transition: all .2s; }  
.ajaxFormStyleStandart .f_switch_checkbox:checked + label:before { background: #9FD468; }  
.ajaxFormStyleStandart .f_switch_checkbox:checked + label:after { left: 17px; }  
.ajaxFormStyleStandart .f_switch_checkbox:focus + label:before { box-shadow: 0 0 0 3px rgba(255,255,0,.5); } 

.ajaxFormStyleStandart .f_radio_button { vertical-align: top; width: 17px; height: 17px; margin: 0 3px 0 0; }
.ajaxFormStyleStandart .f_radio_button + label { cursor: pointer; }
.ajaxFormStyleStandart .f_radio_button:not(checked) { position: absolute; opacity: 0; }
.ajaxFormStyleStandart .f_radio_button:not(checked) + label {  position: relative; padding: 0 0 0 35px; }
.ajaxFormStyleStandart .f_radio_button:not(checked) + label:before { content: ''; position: absolute; top: 50%; left: 0; width: 22px; height: 22px; border: 1px solid #CDD1DA; border-radius: 50%; background: #FFF; transform: translate(0,-50%); }
.ajaxFormStyleStandart .f_radio_button:not(checked) + label:after {  content: ''; position: absolute; top: 50%; left: 4px; width: 16px; height: 16px; border-radius: 50%; background: #9FD468; box-shadow: inset 0 1px 1px rgba(0,0,0,.5); opacity: 0; transition: all .2s; transform: translate(0,-50%); }
.ajaxFormStyleStandart .f_radio_button:checked + label:after { opacity: 1; }
.ajaxFormStyleStandart .f_radio_button:focus + label:before { box-shadow: 0 0 0 3px rgba(255,255,0,.5); }

/************************************/
/* Стандартный стиль Kcaptcha блока */
.ajaxFormStyleStandart .kcaptcha_block { border: none; margin: 30px 20px; padding: 10px 0; width: 300px !important; color: #000; }
.ajaxFormStyleStandart .kcaptcha_block .kcaptcha_img {	width: 120px; cursor: pointer; margin-right: 20px; border: double #ccc;  }
.ajaxFormStyleStandart .kcaptcha_block .kcaptcha_text { border: 1px solid #ccc; width: 130px; color: #555; font-family: Tahoma; padding: 5px; }
.ajaxFormStyleStandart .kcaptcha_block .kcaptcha_title { font: Tahoma; font-size: 12px; padding:  0 0 5px 0; display: block; clear: both; }

/****************************************/
/* Блок прогрессбара для загрузки файла */
.ajaxFormStyleStandart .progress { 
	position:relative; 
	width:400px; 
	border: 1px solid #ddd; 
	padding: 1px; 
	border-radius: 3px; 
	margin: 15px 0 0 0; 
	display: none;
}
.ajaxFormStyleStandart .progressBar { background-color: #B4F5B4; width:0%; height:20px; border-radius: 3px; }
.ajaxFormStyleStandart .progressBarPercent { position:absolute; display:inline-block; top:3px; left:48%; }

/*****************************************************************************/
/* Стиль для AJAX модуля вывода сообщений о неверно введенных данных в форму */
.ajaxFormStyleStandart .warn_color { background-color: #FF9; }
.ajaxFormStyleStandart .warn_message { font-family: Tahoma; font-size: 11px; color: #900;  display: inline-block; margin: 4px 0 4px 210px; } /* Тип блока "inline-block" важен для правильного отображения */
.ajaxFormStyleStandart .warn_message_base { font-family: Tahoma; font-size: 11px; color: #900;  display: block; margin: 4px 0; clear: both; }
.ajaxFormStyleStandart .warn_message_indent { font-family: Tahoma; font-size: 11px; color: #900;  display: inline-block; margin: 0px 0 4px 210px; } /* Тип блока "inline-block" важен для правильного отображения */
.ajaxFormStyleStandart .warn_message_tab_title { font-family: Tahoma; font-size: 11px; color: #900;  display: inline; margin: 4px 0 4px 5px; }

/**************************************************************************************************************/
/* Стиль обвязочных блоков для элементов формы для корректного отображения ошибок у состаных элементов формы. */
.ajaxFormStyleStandart .formElemWrapper { margin: 0; }
.ajaxFormStyleStandart .formElemWrapperIndent { margin: 0 0 0 210px; }

/*************************************/
/* СТАНДАРТНЫЙ СТИЛЬ БЛОКА ФИЛЬТРОВ. */
/*************************************/
.defaultFilterBlock { display: block; padding: 5px 10px; margin: 10px 0; border-top: 1px solid #ccc; border-bottom: 1px solid #ccc; background-color: #F9F9F9; }
.defaultFilterBlock .defaultFilterBlockLine { margin: 0; padding: 7px 0; }
.defaultFilterBlock .defaultFilterBlockLine span { display: inline-block; min-width: 100px; font-weight: bold; font-size: 12px; }
.defaultFilterBlock .defaultFilterBlockLine #searchBlockType { min-width: 150px; max-width: 150px; }
.defaultFilterBlock .defaultFilterBlockLine #searchBlockStr { width: 250px; }
.defaultFilterBlock .defaultFilterBlockLine #sortBy { min-width: 250px; max-width: 250px; }
.defaultFilterBlock .defaultFilterBlockLine input { height: 21px; padding: 0 5px; font-size: 12px; }
.defaultFilterBlock .defaultFilterBlockLine select { height: 25px; padding: 0 5px; font-size: 12px; }
.defaultFilterBlock .defaultFilterBlockLine .f_buttons { margin: 0 5px; padding: 5px 5px; height: 25px; }

/***********************************************************/
/* СТАНДАРТНЫЙ СТИЛЬ СПИСКА РЕЗУЛЬТАТОВ ВЫПОЛНЕНИЯ ЗАПРОСА */
ul.ongDefResultList { position: relative; text-align: left; }
ul.ongDefResultList li { position: relative; text-align: left; list-style: none; padding: 5px 0 5px; }
ul.ongDefResultList li:after { content: '';  width: 6px; height: 6px; position: absolute; top: 12px; left: -17px; background: #a10215; -webkit-border-radius: 10px; -moz-border-radius: 10px; border-radius: 10px; }
ul.ongDefResultList li.errorMsg { padding: 5px 10px 5px 5px; list-style-image: url(/static/imgs_common/icons/error_small_img.png); /* color: #900; */ }
ul.ongDefResultList li.errorMsg:after { display: none;}
ul.ongDefResultList li.successMsg { padding: 5px 10px 5px 5px; list-style-image: url(/static/imgs_common/icons/check_small.png); /* color: #080; */ }
ul.ongDefResultList li.successMsg:after { display: none;}

/*************************************/
/* СТАНДАРТНЫЙ СТИЛЬ КНОПОК В ФОРМАХ */
/*************************************/
.defaultButtonUIGrey { 
	display: inline-block;
	font-size: 1em;
	font-weight: bold;
	cursor: pointer;
	padding: 10px 20px;
	margin: 0 0 10px 0;
	text-decoration: none;
	border: none;
	line-height: 1;
	background-color: #1c1b23; 
	color: #fff;
	border-radius: 50px;
	-webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    -ms-transition: all 0.3s;
    -o-transition: all 0.3s;
    transition: all 0.3s;
}
.defaultButtonUIGrey:link,
.defaultButtonUIGrey:active,
.defaultButtonUIGrey:visited { background-color: #1c1b23; color: #fff; text-decoration: none;  }
.defaultButtonUIGrey:hover { background-color: #fff; color: #000; text-decoration: none; }
.defaultButtonUIGrey span { display: inline-block; margin-right: 10px; }

.defaultButtonUI { 
	display: inline-block;
	font-size: 1em;
	font-weight: bold;
	cursor: pointer;
	padding: 10px 20px;
	margin: 0 0 10px 0;
	text-decoration: none;
	line-height: 1;
	background-color: #1c1b23; 
	color: #fff;
	border-radius: 50px;
	-webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    -ms-transition: all 0.3s;
    -o-transition: all 0.3s;
    transition: all 0.3s;
}
.defaultButtonUI:link,
.defaultButtonUI:active,
.defaultButtonUI:visited { background-color: #1c1b23; color: #fff; text-decoration: none; }
.defaultButtonUI:hover { background-color: #cf232c; color: #fff; text-decoration: none; }
.defaultButtonUI span { display: inline-block; margin-right: 10px; }

/*************************************/
/* СТАНДАРТНЫЕ СТИЛИ ДИАЛОГОВЫХ ОКОН */
/* Стандартное окно с сообщениями */
.dialogMessageBlock { position: relative; z-index: 10; margin: 10px 0 25px 0; }
.dialogButtonsBlock { text-align: center; position: relative; z-index: 20; }
.dialogButton {
	display: inline-block;
	font-size: 1em;
	font-weight: bold;
	cursor: pointer;
	padding: 10px 20px;
	margin: 0 0 10px 0;
	text-decoration: none;
	line-height: 1;
	background-color: #0d4da1; 
	color: #fff;
	border-radius: 50px;
	-webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    -ms-transition: all 0.3s;
    -o-transition: all 0.3s;
    transition: all 0.3s;
}
.dialogButton:link,
.dialogButton:active,
.dialogButton:visited { background-color: #0d4da1; color: #fff; text-decoration: none; }
.dialogButton:hover { background-color: #cf232c; color: #fff; text-decoration: none; }
