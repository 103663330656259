﻿/* Misc classes */
.small { font-size: 0.9em; }
.smaller { font-size: 0.8em; }
.smallest { font-size: 0.7em; }

.large { font-size: 1.1em; }
.larger { font-size: 1.2em; }
.largest { font-size: 1.3em; }

.hidden { display: none; }
.clear { clear: both; }

.quiet { color: #666; }
.loud { color: #000; }
.highlight { background: #FF0; }

.text-left { text-align: left; }
.text-right { text-align: right; }
.text-center { text-align: center; }

.error, .notice, .success { border: 1px solid #DDD; margin-bottom: 1em; padding: 0.6em 0.8em; }

.error { background: #FBE3E4; color: #8A1F11; border-color: #FBC2C4; }
.error a { color: #8A1F11; }
.error p { margin-bottom: 0; }

.notice { background: #FFF6BF; color: #514721; border-color: #FFD324; }
.notice a { color: #514721; }
.notice p { margin-bottom: 0; }

.success { background: #E6EFC2; color: #264409; border-color: #C6D880; }
.success a { color: #264409; }
.success p { margin-bottom: 0; }

div.more { padding-top: 6px; }
.more a, a.more { color: #666; }
.more a:hover, a.more:hover { color: #002; }

/*********/
/* Формы */
.form-error { border-color: #F00; }

/***************************/
/* Расположение эелементов */

/* Основное */
.center,.aligncenter { display: block; margin-left: auto; margin-right: auto; }

/* Изображения */
img.bordered,img.alignleft,img.alignright,img.aligncenter { background-color: #FFF; border: 1px solid #DDD; padding: 3px; }
img.alignleft, img.left { margin: 0 1.5em 1em 0; }
img.alignright, img.right { margin: 0 0 1em 1.5em; }

/* Floats */   
.left,.alignleft { float: left; }
.right,.alignright { float: right; }
.clear,.clearer { clear: both; }
.clearer { display: block; font-size: 0; line-height: 0; }

/* Колонки */
.col2 { width: 47%; }

/***********************************************/
/* ДОПОЛНИТЕЛЬНЫЕ СТИЛИ (бордюры, отступы...)  */

/* Бордюры */
.noborder { border: 0; }
.notborder { border-top: 0; }
.norborder { border-right: 0; }
.nobborder { border-bottom: 0; }
.nolborder { border-left: 0; }

/* Margin */
.nomargin { margin: 0; }
.notmargin { margin-top: 0; }
.normargin { margin-right: 0; }
.nobmargin { margin-bottom: 0; }
.nolmargin { margin-left: 0; }

/* Padding */
.nopadding { padding: 0; }
.notpadding { padding-top: 0; }
.norpadding { padding-right: 0; }
.nobpadding { padding-bottom: 0; }
.nolpadding { padding-left: 0; }

/* Single Line IE Fixes */
* html .separator-vertical, * html .content { height: 0.01%; min-height: 0.01%; }

/* Default inline HR style */
hr { border: 0; height: 0; border-top: 1px solid rgba(0, 0, 0, 0.1); border-bottom: 1px solid rgba(255, 255, 255, 0.3); }

/*********************************/
/* Стиль иконки AJAX-обработчика */  
#ajax_loader_icon { z-index: 1000; position: fixed; left: 3%; top: 3%; }
